import { useFrame } from '@react-three/fiber';
import { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';



const MatrixCubesEffect = ({ numberOfCubes, fontSize, textureSize, cubeAnimation, radius }) => {
    const textureRef = useRef();
    const canvasRef = useRef(document.createElement('canvas'));
    const meshRef = useRef();
    const choice = (arr) => {
        return arr[Math.floor(Math.random() * arr.length)];
    }
    const randomString = useMemo(generateRandomString,[fontSize,textureSize]);
    function generateRandomString(){
       return Array.from({length: textureSize/fontSize+10}, () => 
            Array.from({length: textureSize/fontSize*1.5+fontSize}, () => choice(['0','1',' '])).join('')
            );
    }
    const updateCanvas = (ctx, clock) => {
            const elapsedTime = clock.elapsedTime;
            ctx.fillStyle = 'green';
            ctx.font = `bold ${fontSize}px monospace`;
            for(let i=0;i<textureSize/fontSize+1;i++){
                if(cubeAnimation)
                    ctx.fillText(randomString[i], 0, ((fontSize*elapsedTime+10)+i*fontSize) % (textureSize+fontSize*1.5));
                else {
                    ctx.fillText(randomString[i],0,fontSize*i);
                }
                textureRef.current.needsUpdate = true;
            }
    }
    useEffect( () => {
        textureRef.current.dispose();
    }, [textureSize])
    useEffect( () => {
        for(let i=0; i< numberOfCubes; i++){
            const matrix = new THREE.Matrix4();
            const quaterion = new THREE.Quaternion();
            quaterion.setFromEuler(new THREE.Euler(
                (Math.random() - 0.5) * Math.PI * 2,
                (Math.random()- 0.5) * Math.PI * 2, 
                0));
            const position = new THREE.Vector3(
                (Math.random() - 0.5) * radius,
                (Math.random() - 0.5) * radius,
                (Math.random() - 0.5) * radius
            )
            const scale = (Math.random() - 0.5) * 4
            
            matrix.compose(position,quaterion, new THREE.Vector3(
                scale,
                scale,
                scale
            ))
            meshRef.current.setMatrixAt(i, matrix);
        }
        meshRef.current.instanceMatrix.needsUpdate = true;
    }, [radius, numberOfCubes,textureSize])
    useFrame(({ clock }) => {
        canvasRef.current.width = textureSize;
        canvasRef.current.height = textureSize;
        let ctx = canvasRef.current.getContext('2d');
        updateCanvas(ctx, clock)
    });
    
    return ( 
            <>
                <instancedMesh ref = {meshRef} args = {[null,null, numberOfCubes]}>
                    <boxGeometry/>
                    <meshBasicMaterial transparent= { true } opacity ={0.75} side={THREE.DoubleSide}>
                    <canvasTexture
                            ref={textureRef} 
                            attach="map"
                            image={canvasRef.current} 
                                    />
                    </meshBasicMaterial>
                </instancedMesh>
            </>
           );
           
        
}
 
export default MatrixCubesEffect;

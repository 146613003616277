import MatrixLoading from "./MatrixLoading";
import AnimatedCanvasBg from "./AnimatedCanvasBg";
import { useState } from "react";
import ControlPanel from "./ControlPanel";
const Herosection = ({ welcomeText }) => {
    const [numberOfCubes, setNumberOfCubes] = useState(30);
    const [cubeAnimation,setAnimation] = useState(true);
    const [textureSize, setTextureSize] = useState(256);
    const [radius, setRadius] = useState(10);
    const fontSize = 48;
    function handleCubes(e, value) {
        setNumberOfCubes(value);
        //console.log(value)
    }
    function handleAnimation(e,value) {
     //   console.log(value)
        setAnimation(value);
    }
    function handleTextureSize(e,value){
     //   console.log(value)
        setTextureSize(Math.pow(2,value+6));
    }
    function handleRadius(e,value){
     //   console.log(value)
        setRadius(value);
    }
    const controlPanelArgs = {
        handleCubes,
        handleAnimation,
        handleTextureSize,
        handleRadius
    }
    const animatedCanvasArgs = {
        numberOfCubes,
        fontSize,
        cubeAnimation,
        textureSize,
        radius,
    }

   // console.log(controlPanelArgs)
    if(true){
        return (
        <div className="flex flex-1 justify-center flex-col bg-black relative">
            <AnimatedCanvasBg {...animatedCanvasArgs}/>
            <ControlPanel {...controlPanelArgs }/>
            <div className="absolute text-green-500 text-center w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 uppercase text-3xl font-mono font-black blink">{ welcomeText }</div>
            <div className="glitch text-green-500 text-center absolute w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 uppercase text-3xl font-mono font-black overflow-hidden" data-glitch={ welcomeText }>{welcomeText}</div>
        </div>
        );
    }
     else{   
            return <div className="flex flex-col justify-center"><MatrixLoading/></div>;
     }
}
 
export default Herosection;
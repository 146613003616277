import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import  MatrixCubesEffect  from './MatrixCubesEffect';
const AnimatedCanvasBg = (props) => {

    
    return (
            <Canvas>
                <PerspectiveCamera position-z={5} makeDefault/>
                <OrbitControls zoom0 = {-10} enableZoom={ false }/>
                <MatrixCubesEffect {...props} />
            </Canvas>
    )
}
 
export default AnimatedCanvasBg;
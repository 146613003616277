import {Slider, Switch} from '@mui/material'
import { useRef, useState } from 'react';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
const ControlPanel = ( {   
        handleCubes,
        handleAnimation,
        handleTextureSize,
        handleRadius
} ) => {
    const myDiv = useRef();
    const [visible,setVisible] = useState(true);
    function handleOnClick(e) {
        setVisible(!visible);
    }
    if(visible)
    return (
            <div className="absolute bg-gray-600 bg-opacity-60 justify-items-center justify-center top-0 left-0 rounded-md w-1/12 h-1/12 min-w-fit" ref={myDiv}>
                <VisibilityOffIcon onClick={handleOnClick}/>
                <div className="m-5 flex flex-col justify-center justify-items-center gap-2" >
                    <p>Number of Cubes</p>
                    <Slider
                        min = { 0 }
                        max = { 1000 }
                        valueLabelDisplay = "auto"
                        defaultValue={25}
                        slotProps = {{
                            root: { className: ''},
                            rail: { className: 'green'}
                        }}
                        onChange = {handleCubes}
                        sx={{
                            color: 'green'
                        }}
                    />
                    <p>Radius</p>
                    <Slider
                        min = { 10 }
                        max = { 100 }
                        valueLabelDisplay = "auto"
                        slotProps = {{
                            root: { className: ''},
                            rail: { className: 'green'}
                        }}
                        onChange = {handleRadius}
                        sx={{
                            color: 'green'
                        }}
                    />
                    <p>Texture Size</p>
                    <Slider
                        className = "textureSize"
                        min = { 1 }
                        max = { 5 }
                        step = {1}
                        valueLabelDisplay = "auto"
                        scale = { (x) => Math.pow(2,(x+6)) }
                        sx={{
                            color: 'green'
                        }}
                        onChange = { handleTextureSize}/>
                    <p>Animation</p>
                    <Switch className ="animation self-center"
                    sx={{
                        borderRadius: 2,
                        "& .MuiSwitch-switchBase.Mui-checked": {
                            color: "green"
                        },
                        "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                            backgroundColor: 'lightgreen'
                        },
                    }}
                    defaultChecked
                    onChange = {handleAnimation}/>
                </div>

            </div>
      );
    else return (
        <div className="absolute bg-gray-600 bg-opacity-60 top-0 left-0">
        <VisibilityIcon onClick= {handleOnClick}/>
        </div>
    );
}
 
export default ControlPanel;
import Herosection from "./components/Herosection";
import Navbar from "./components/Navbar";
import {useEffect} from 'react'

function App() {
  const pages = ['About', 'Projects', 'Contact'];
  const welcomeText = "Hello I'm Mikołaj";
  useEffect(() => {
    document.title = "Mikołaj's Homepage"
  })
  return (
    <div className="flex flex-col h-screen font-mono" >    
      <Navbar pages={pages}/>
      <Herosection welcomeText= {welcomeText}/>
    </div>
  );
}

export default App;
